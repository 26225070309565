import PropTypes from 'prop-types';
import classNames from 'classnames';
import { components } from 'react-select';

const propTypes = {
  data: PropTypes.shape({
    __isError__: PropTypes.bool,
  }).isRequired,
  innerProps: PropTypes.shape({
    className: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

function SelectMultiValueContainer({ innerProps, ...props }) {
  return (
    <components.MultiValueContainer
      {...props}
      innerProps={{
        ...innerProps,
        className: classNames(innerProps.className, {
          'react-select__multi-value--error': props.data.__isError__,
        }),
      }}
    />
  );
}

SelectMultiValueContainer.propTypes = propTypes;
SelectMultiValueContainer.defaultProps = defaultProps;

export default SelectMultiValueContainer;
