import { useRef } from 'react';
import ReactSelect, { createFilter } from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuList from '@/storychief/components/SelectMenuList';
import LoadingIndicator from '@/storychief/components/SelectLoadingIndicator';
import Input from '@/storychief/components/SelectInput';
import CreatableOption from '@/storychief/components/SelectCreatableOption';
import MultiValueContainer from '@/storychief/components/SelectMultiValueContainer';
import { useEmitEventEffect } from '@/storychief/hooks';

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  isMulti: PropTypes.bool,
  isMultiCreatable: PropTypes.bool,
  isOptimizedRender: PropTypes.bool,
  components: PropTypes.shape({}),
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'creatable']),
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getNewOptionData: PropTypes.func,
  id: PropTypes.string,
  hideNoOptionsMessage: PropTypes.bool,
  hideDropdownIndicator: PropTypes.bool,
};
const defaultProps = {
  id: null,
  value: undefined,
  isMulti: false,
  isMultiCreatable: false,
  isOptimizedRender: false,
  components: null,
  className: null,
  type: 'default',
  isRequired: false,
  options: [],
  onChange: () => {},
  onCreateOption: null,
  getOptionValue: (option) => option?.value,
  getOptionLabel: (o) => o.label,
  getNewOptionData: (value, label) => ({ value, label, __isNew__: true }),
  hideNoOptionsMessage: false,
  hideDropdownIndicator: false,
};

function Select({
  id,
  value,
  isRequired,
  isMulti,
  isMultiCreatable,
  components,
  isOptimizedRender,
  className,
  type,
  options,
  onChange,
  onCreateOption,
  getOptionValue,
  getOptionLabel,
  getNewOptionData,
  hideNoOptionsMessage,
  hideDropdownIndicator,
  ...props
}) {
  // Variables
  const isCreatable = type === 'creatable';
  const SelectComponent = isCreatable ? ReactSelectCreatable : ReactSelect;

  // Refs
  const selectInputRef = useRef();
  const selectComponents = useRef(getComponents());

  if (selectInputRef?.current?.select?.inputRef) {
    selectInputRef.current.select.inputRef.required = isRequired;
  }

  // Hooks
  useEmitEventEffect(() => {
    selectInputRef.current.focus();
  }, [`select-focus-${id}`]);

  // Functions
  function getClassName() {
    return classNames(
      'react-select--custom',
      `react-select--${isMulti ? 'multiple' : 'single'}`,
      className,
    );
  }

  function getComponents() {
    return {
      ...components,
      MultiValueContainer,
      LoadingIndicator,
      Input,
      ...(isOptimizedRender ? { MenuList } : null),
      ...(isMulti && isMultiCreatable && type === 'creatable' ? { Option: CreatableOption } : null),
      ...(hideDropdownIndicator
        ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
        : null),
    };
  }

  function isNewOptionEmpty(option) {
    return option.__isNew__ && `${getOptionValue(option ?? '')}`.trim().length === 0;
  }

  function handleOnChange(values, data) {
    if (data.option?.__isNew__) {
      handleOnCreateOption(data.option.id || data.option.value, data.option.__isMultiple__);
    } else if (!isNewOptionEmpty(data.option || {}) || data.removedValue || data.removedValues) {
      onChange(values, data);
    }
  }

  async function handleOnCreateOption(optionValue, hasMultiple = false) {
    const newValue = isMulti ? [...value] : [];

    if (isMulti && isMultiCreatable && hasMultiple) {
      const multipleNewValues = optionValue.split(',').map((v) => v.trim());

      multipleNewValues.forEach((multipleNewValue) => {
        const existing = options.find((o) => getOptionLabel(o) === multipleNewValue);
        const isExistingSelected = existing
          ? value.some(
              (o) => o.id === existing.id || (o.value !== undefined && o.value === existing.value),
            )
          : false;

        if (isExistingSelected) {
          // Do nothing if an existing option is already selected
        } else if (existing) {
          // Add an existing option
          newValue.push(existing);
        } else {
          // Create a new option
          newValue.push(getNewOptionData(multipleNewValue, multipleNewValue));
        }
      });
    } else {
      newValue.push(getNewOptionData(optionValue, optionValue));
    }

    if (onCreateOption !== null && newValue.some((v) => v.__isNew__)) {
      onCreateOption(newValue);
    } else {
      onChange(isMulti ? newValue : newValue[0]);
    }
  }

  function getNoOptionsMessage() {
    return () => (value?.length || hideNoOptionsMessage ? null : 'No results found');
  }

  // Render
  return (
    <SelectComponent
      ref={selectInputRef}
      className={getClassName()}
      classNamePrefix="react-select"
      filterOption={createFilter({ ignoreAccents: false })}
      components={selectComponents.current}
      noOptionsMessage={getNoOptionsMessage()}
      isMulti={isMulti}
      options={options}
      onChange={handleOnChange}
      onCreateOption={handleOnCreateOption}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      getNewOptionData={getNewOptionData}
      value={value}
      closeMenuOnSelect={!isMulti}
      openMenuOnFocus={!!id}
      {...props}
    />
  );
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
