import PropTypes from 'prop-types';
import { components } from 'react-select';

const propTypes = {
  children: PropTypes.node,
};
const defaultProps = {
  children: null,
};

function SelectInput({ children, ...rest }) {
  return (
    <components.Input {...rest} spellCheck>
      {children}
    </components.Input>
  );
}

SelectInput.propTypes = propTypes;
SelectInput.defaultProps = defaultProps;

export default SelectInput;
