import { FixedSizeList as List } from 'react-window';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  itemHeight: PropTypes.number,
  maxHeight: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  children: PropTypes.node,
};
const defaultProps = {
  itemHeight: 39,
  children: [],
};

function SelectMenuList(props) {
  const { options, children, maxHeight, getValue, itemHeight } = props;
  const itemsHeight = itemHeight * children.length;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * itemHeight;

  return children.length > 0 ? (
    <List
      height={itemsHeight < maxHeight ? itemsHeight : maxHeight}
      itemCount={children.length}
      itemSize={itemHeight}
      initialScrollOffset={initialOffset}
      className="react-select__menu-list"
    >
      {({ index, style }) => {
        const className = classNames('react-select__menu-item', {
          'react-select__menu-item--first': index === 0,
          'react-select__menu-item--last': index === children.length - 1,
        });

        return (
          <div className={className} style={style}>
            {children[index]}
          </div>
        );
      }}
    </List>
  ) : (
    children
  );
}

SelectMenuList.propTypes = propTypes;
SelectMenuList.defaultProps = defaultProps;

export default SelectMenuList;
