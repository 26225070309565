import PropTypes from 'prop-types';
import { components } from 'react-select';
import classNames from 'classnames';

const propTypes = {
  data: PropTypes.shape({
    __isNew__: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node.isRequired,
  selectOption: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};
const defaultProps = {
  className: null,
};

function SelectCreatableOption(props) {
  if (props.data.__isNew__ && props.value.includes(',')) {
    return (
      <components.Option
        {...props}
        className={classNames(props.className, 'react-select__option--custom-creatable')}
      >
        {props.children}
        <button
          onClick={(e) => {
            e.stopPropagation();
            props.selectOption({
              ...props.data,
              __isMultiple__: true,
            });
          }}
          type="button"
          className="btn btn-link btn-chromeless"
        >
          Create multiple
        </button>
      </components.Option>
    );
  }

  return <components.Option {...props} />;
}

SelectCreatableOption.propTypes = propTypes;
SelectCreatableOption.defaultProps = defaultProps;

export default SelectCreatableOption;
